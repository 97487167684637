.flex-container {
    display: flex;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column;
}

.flex-align-items-center {
    align-items: center;
}

.flex-justify-center {
    justify-content: center;
}

.flex-justify-space-between {
    justify-content: space-between;
}

.flex-justify-space-evenly {
    justify-content: space-evenly;
}

.fill-container {
    height: 100%;
    width: 100%;
}

.pos-relative {
    position: relative;
}
.pos-absolute {
    position: absolute;
}

.mg-vertical-10 {
    margin-top: 10%;
    margin-bottom: 10%;
}

.mg-vertical-20 {
    margin-top: 20%;
    margin-bottom: 20%;
}

.mg-vertical-30 {
    margin-top: 30%;
    margin-bottom: 30%;
}

.mg-vertical-40 {
    margin-top: 40%;
    margin-bottom: 40%;
}

.MuiInputBase-input.Mui-disabled {
    opacity: 0.5 !important;
}

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline{
    border-color: rgba(255, 255, 255, 0.25) !important ;
}

.MuiPickersBasePicker-container > .MuiToolbar-root {
    padding-left: 12px;
    padding-right: 12px;
}

.MuiPickersBasePicker-container > .MuiToolbar-root > button {
    padding: 5px
}

.MuiPickersBasePicker-container > .MuiToolbar-root > button > .MuiButton-label > .MuiTypography-subtitle1 {
    font-size: 1.125em;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(1.5);
  }
  75% {
    transform: scale(1.75);
  }
}